<template>
  <div
    class="tile is-ancestor"
    style="padding-left: 1%; padding-right: 1%; padding-top: 1%"
  >
    <div class="tile is-parent is-3">
      <div class="tile is-child box">
        <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
        <b-field>
          <b-select
            v-model="selectedLoader"
            :placeholder="$t('Data loader...')"
            icon="upload"
            expanded
          >
            <option value="ResourceLoader">
              {{ $t('Create/Replace Resource') }}
            </option>
            <option value="ResourceUpdater">{{ $t('Update Resource') }}</option>
            <option value="UserLoader">{{ $t('Create/Replace User') }}</option>
            <option value="UserUpdater">{{ $t('Update User') }}</option>
            <option value="SkillLoader">
              {{ $t('Create/Replace Skill') }}
            </option>
            <option value="CollabGroupLoader">
              {{ $t('Load User Collaboration Groups') }}
            </option>
            <option value="ResourceInventoryLoader">
              {{ $t('Load Resource Inventory') }}
            </option>
            <option value="ResourceWorkZoneLoader">
              {{ $t('Load Resource Work Zones') }}
            </option>
            <option value="ResourceWorkSkillLoader">
              {{ $t('Load Resource Work Skills') }}
            </option>
            <option value="ResourceLocationLoader">
              {{ $t('Load Resource Locations') }}
            </option>
            <option value="ResourceLocationAssigner">
              {{ $t('Assign Resource Locations') }}
            </option>
            <option value="ResourceWorkScheduleLoader">
              {{ $t('Load Resource Work Schedules') }}
            </option>
            <option value="WorkZoneLoader">
              {{ $t('Bulk Load Work Zones') }}
            </option>
            <option value="InventoryLoader">
              {{ $t('Create Inventory') }}
            </option>
            <option value="PropertiesLoader">
              {{ $t('Create/Replace Properties') }}
            </option>
            <option value="EnumerationsLoader">
              {{ $t('Create/Update Enumerations') }}
            </option>
          </b-select>
        </b-field>
        <b-field>
          <b-select
            v-model="targetEnvironment"
            name="Select target environment"
            :placeholder="$t('Target...')"
            icon="database"
            expanded
          >
            <option
              v-for="option in environmentList"
              :id="option.id"
              :key="option.name"
              :value="option.name"
            >
              {{ option.friendlyName }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="tile is-parent">
      <div class="tile is-child box">
        <div v-if="targetEnvironment && selectedLoader">
          <keep-alive>
            <component
              :is="selectedLoader"
              :environment="targetEnvironment"
              :customer="customer"
              style="overflow-x: scroll"
            />
          </keep-alive>
        </div>
        <div v-else>
          <p class="is-size-4 has-text-grey">
            {{ $t('Please select a data-loader and target environment') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'LoaderContainer',
  components: {
    ResourceLoader: () => import(/* webpackPreload: true */ './ResourceLoader'),
    ResourceUpdater: () =>
      import(/* webpackPreload: true */ './ResourceUpdater'),
    UserLoader: () => import(/* webpackPreload: true */ './UserLoader'),
    UserUpdater: () => import(/* webpackPreload: true */ './UserUpdater'),
    CollabGroupLoader: () =>
      import(/* webpackPreload: true */ './CollabGroupLoader'),
    ResourceInventoryLoader: () =>
      import(/* webpackPreload: true */ './ResourceInventoryLoader'),
    ResourceWorkZoneLoader: () =>
      import(/* webpackPreload: true */ './ResourceWorkZoneLoader'),
    ResourceWorkSkillLoader: () =>
      import(/* webpackPreload: true */ './ResourceWorkSkillLoader'),
    ResourceLocationLoader: () =>
      import(/* webpackPreload: true */ './ResourceLocationLoader'),
    ResourceLocationAssigner: () =>
      import(/* webpackPreload: true */ './ResourceLocationAssigner'),
    WorkZoneLoader: () => import(/* webpackPreload: true */ './WorkZoneLoader'),
    ResourceWorkScheduleLoader: () =>
      import(/* webpackPreload: true */ './ResourceWorkScheduleLoader'),
    InventoryLoader: () =>
      import(/* webpackPreload: true */ './InventoryLoader'),
    PropertiesLoader: () =>
      import(/* webpackPreload: true */ './PropertyLoader'),
    EnumerationsLoader: () =>
      import(/* webpackPreload: true */ './EnumerationsLoader'),
    SkillLoader: () => import(/* webpackPreload: true */ './SkillLoader'),
  },
  data() {
    return {
      selectedLoader: null,
      targetEnvironment: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('storeEnvironments', ['environmentList']),
  },
  created() {
    this.fetchEnvironments(false);
  },
});
</script>
